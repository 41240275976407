
.tags .tag[data-v-9be73976] {
  cursor: pointer;
}


.tags .tag[data-v-8aa935ae] {
  cursor: pointer;
}


.filtraPeriodoContainer[data-v-050e43be] {
  -webkit-box-align: left;
      -ms-flex-align: left;
          align-items: left;
  -ms-flex-line-pack: left;
      align-content: left;
  justify-items: left;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
}
.panel-heading[data-v-050e43be] {
  text-align: left;
}
.table thead tr th div .th-wrap > span .MIACLASSE[data-v-050e43be] {
  -ms-flex-line-pack: stretch;
      align-content: stretch;
}
.centralPanel[data-v-050e43be] {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.has-importo-positivo[data-v-050e43be] {
  color: #1bb551;
}
.has-importo-negativo[data-v-050e43be] {
  color: #dd2b4f;
}


/*# sourceMappingURL=chunk-a1744124.b4637f86.css.map*/